<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>

        <md-button
          class="md-primary float-right md-round md-sm"
          style="margin-top: -20px"
          :disabled="!tableHasContent"
          @click="onDownload"
        >
          <md-icon>get_app</md-icon>
          Download as XLS
        </md-button>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mb-5">
          <div class="md-layout-item md-size-33">
            <ProgrammeStatusesSelect
              v-model="filters.status"
            />
          </div>
          <div class="md-layout-item md-size-33">
            <PackageItemsSelect
              v-model="filters.package_item_id"
              label="Package Item"
            />
          </div>
          <div class="md-layout-item md-size-33">
            <FormSelect
              v-model="filters.with_used"
              label="Show Used Items"
              :options="[
                {id: true, name: 'YES'},
                {id: false, name: 'NO'},
              ]"
            />
          </div>

          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getData"
              @onSetUsed="onSetUsed"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import {
  ProgrammeStatusesSelect,
  PackageItemsSelect,
} from '@/components/Inputs/selects';
import {
  FormSelect,
} from '@/components/Inputs';
import moment from 'moment';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    ProgrammeStatusesSelect,
    PackageItemsSelect,
    VueTable,
    FormSelect,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: 'package.programme.student.student_number',
          mask: 'student no.',
          sortable: true,
        },
        {
          title: 'package.programme.student.full_name',
          mask: 'name',
          sort_value: 'package.programme.student.first_name',
          sortable: true,
        },
        {
          title: 'package.programme.student.email',
          mask: 'email',
          sortable: true,
        },
        {
          title: 'price',
          mask: 'Price',
          sortable: true,
        },
        {
          title: 'package.programme.status',
          mask: 'programme status',
          sortable: true,
        },
        {
          title: 'package_item.name',
          mask: 'Package Item',
          sortable: true,
        },
        {
          title: 'used_at',
          mask: 'used at',
          sortable: true,
          dateTimeFormat: true,
        },
      ],
      values: {},
      actions: [
        {
          buttonClass: 'md-primary',
          tooltip: 'Set Used',
          callback: 'onSetUsed',
          icon: 'done',
        },
      ],
    },
    filters: {
      status: null,
      package_item_id: null,
      with_used: false,
    },
    loading: false,
    params: {},
  }),
  computed: {
    tableHasContent() {
      return this.vTable.values?.data?.length > 0;
    },
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    onFilter() {
      this.$refs.vtable.init();

      this.params = { ...this.filters, ...this.params };
    },
    getData(params) {
      this.params = { ...this.filters, ...params };
      this.loading = true;

      this.request('packages/items/report', 'get', { ...this.params }, ({ data }) => {
        this.vTable.values = data;
        this.vTable.values.data = this.vTable.values.data.map((x) => {
          x.onSetUsed = x.used_at === null;
          return x;
        });
      }, () => {
        this.loading = false;
      });
    },
    onSetUsed(item) {
      this.loading = true;

      this.request(`packages/items/${item.package_package_item_id}`, 'put', null, () => {
        this.$refs.vtable.init();
      });
    },
    onDownload() {
      this.loading = true;
      this.request(
        'packages/items/report',
        'download',
        {
          ...this.params,
          download: true,
        },
        ({ data }) => {
          const filename = `packages_items_report_${moment().format(
            'YYYY_MM_DD',
          )}.xlsx`;
          downloadFile(data, filename);
        },
        () => {
          this.loading = false;
        },
      );
    },
  },
};
</script>
